body {
  background-color: #0d0512;
  // position: absolute;
  // z-index: 1;
}
.landing {
  text-align: center;
  min-height: calc(100vh - 170px);
  max-width: 1280px;
  width: calc(100vw - 40px);
  margin: 0 auto;

  .connect-wallet-span{
    color: #3BCF60;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(min(400px, max(320px, 33%))), 1fr));
    grid-gap: 40px;
    align-items: center;
    justify-content: center;
  }

  .katana{
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .upper-text{
    font-weight: bold;
    color: #8eFB79;
    span{
      display: inline-block;
      color: white;
    }
  }

  .page-loading{
      text-align: center;
      margin: auto;
  }
  .body-bg {
    background-color: rgb(13,5,18);
    height: 100% !important;
    display: inline-flex;
    width:100%;
  }

  .custom_input {
    background-color: #524347;
    color: white !important;
    // font-size: 2rem !important;
    font-weight: bold;
    border: none;
    text-align: right;
    border-radius: 10px;
  }

  .skew-btn{
    background: #429B2B;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    min-width: 40%;
    .content{
      transform: skew(0deg) !important;
    }
  }
  .claim-color{
    color: #3BCF60;
  }
  .no-kata-purchased{
    color:#3BCF60;
  }
  .no-seedsale-start{
    color:#3BCF60;
  }
  .no-claim-start{
    color:#3BCF60;
  }
  .claim-now-color{
    color:#3BCF60;
  }
  .claim-info{
    color: rgb(136, 133, 133);
  }
  .claim-info-text{
    color:#3BCF60;
  }
  .box{
    border: 2px solid;
    border-radius: 10px;
    border-color: rgb(136, 133, 133);
  }
}
