.kata-footer {
    background-color: #171319;
    width:100%;

    &__text{
      color: #ADADAD;
      text-align: center;
      font-size:12px;
    }
    &__icon{
      height: 40px;
      margin: 10px;
      @media only screen and (max-width: 789px) {
        height: 30px;
      }
    }
  }
  