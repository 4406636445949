.kata-header {
  background-color: #171319;
  width: 100%;

  &__logo {
    height: 55px;
    margin:15px 15px 15px 70px;
  }

  &__link{
    margin: 10px;
    color: white;
  }

  &__connect-wallet-btn{
    border-radius: 25px !important;
    border-width: 1px !important;
    border-color: #429B2B !important;
    background-color: #429B2B !important;
    color: white !important;
  }
  
  &__connect-wallet-btn:hover, &__connect-wallet-btn:active, &__connect-wallet-btn:checked, &__connect-wallet-btn:focus{
    background-color: #429B2B !important;
    border-color: #429B2B;
    box-shadow:#429B2B !important;
    color: white !important;
  }
  
  &__wallet-icon {
    margin-right: 10px;
  }
}
  