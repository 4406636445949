@import "~bootstrap/scss/bootstrap";

@import "./scss/style.scss";

.font_avertastd_regular {
    font-family: 'Avertastd-Regular', sans-serif;
}

.font_rifficfree {
    font-family: 'Rifficfree-Bold', sans-serif;
}